@import "bootstrap/bootstrap";
@import "zenburn/zenburn";

$header-bg-image: "/assets/images/home1.png";
$header-bg-image-mobile: "/assets/images/heager-bg-mobile.png";
$white: #ffffff ! default;
$levelonegray: #dfe6e9;
$leveltwogray: #8395a7;

html,
body {
  height: 100%;
}

html {
  font-size: 14px;
}

.brandlogo {
  max-width: 100px;
}

.nav-link {
  color: #000000 !important;
}

.rowone {
  background-image: url("/assets/images/home2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.rowtwo {
  background-image: url("/assets/images/home3.png");
  background-repeat: repeat-x;
  background-size: contain;
  background-color: #222f3e;
}

.titletext {
  font-weight: 300;
  font-size: 1.8rem;
}

.cardcodef5 {
  height: 21rem;
  padding: 1rem;
  color: white;
}

.cardcodef5 .titletext {
  font-weight: 300;
  font-size: 1.5rem;
}

.cardpurple {
  background: rgba(120, 128, 252, 0.8);
}

.cardblue {
  background: rgba(104, 180, 255, 0.8);
}

.cardorange {
  background: rgba(38, 222, 129, 0.8);
}

.cardprojects {
  min-height: 10rem;
  background-image: url("/assets/images/home4.png");
  background-size: contain;
}

.projectpurple {
  background-color: rgba(120, 128, 251, 0.3);
}

.projectblue {
  background-color: rgba(104, 180, 255, 0.3);
}

.projectyellow {
  background-color: rgba(254, 225, 116, 0.3);
}

.contactrow {
  min-height: 10rem;
  min-width: 100%;
  background-image: linear-gradient(
    to right,
    rgba(30, 144, 255, 0.9),
    rgba(55, 66, 250, 0.9)
  );
  color: white;
}

.contactpage {
  background-image: url("/assets/images/home2.png");
  background-repeat: repeat;
  background-size: contain;
}

.projectdetailspage {
  padding-top: 65px;
}

.projectdetailsbackground {
  background-image: url("/assets/images/home3.png");
  background-repeat: repeat-x;
  background-size: contain;
}

.mediaroundicon {
  background-color: $levelonegray;
  height: 50px;
  width: 50px;
  display: inline-flex;
  justify-content: center;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
  .brandlogo {
    max-width: 150px;
  }
}

.btncodef5 {
  color: $white;
}

.flexitem {
  background-color: $white;
}

.footerrow {
  background-color: white;
}

.footertext {
  color: #222f3e;
  text-decoration: none !important;
}

.projectsbackground {
  background-color: $white;
}

.projectsbackground:hover {
  background-color: $dark;
  color: $white;
}

.nounderline:hover {
  text-decoration: none !important;
  color: inherit;
}

.navncontent {
  min-height: 100%;
}

.devimage {
  height: 150px;
  width: 150px;
  display: block;
  object-fit: none;
  object-position: center;
  background-color: #a6a6a6;
}

.profileunderline:hover {
  text-decoration: none !important;
  color: $primary;
}

.contentcontainer {
  min-height: 100%;
}

.centerimage {
  object-position: center;
}

.rowthree {
  background-color: $primary;
}

.profiletitletext {
  color: white;
}

.profileul {
  list-style-type: none;
}

.profilesubtext {
  color: white;
  font-size: 12px;
}

.profileicon {
  height: 20px;
  width: 20px;
  display: inline-flex;
  justify-content: center;
}

.activepage {
  text-decoration: underline;
}

.card {
  border: none;
}

.card-title {
  font-weight: 350;
}

pre {
  margin: 1rem;
  padding: 1rem;
}
